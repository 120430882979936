<template>
  <b-modal
    id="successfully-cancel-subscription-modal"
    centered
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    hide-footer
    hide-header
  >
    <div class="text-center mt-1">
      <img
        src="@/assets/images/icons/uniHead/subscription/tick.svg"
        img-fluid
        class="mx-1 cursor-pointer"
        width="50px"
        height="50px"
      />
      <h4 class="py-1 font-weight-bolder text-colorBlack">Successfully</h4>
      <p class="font-weight-bold text-colorGray">
        We will inform you soon once admin can review your request and we will
        send you request.
      </p>
    </div>
    <div class="mb-50 text-center">
      <b-button type="submit" block variant="colorBlue" rounded>
        Continue
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  props: {},
  mixins: [util],
  data() {
    return {
      departmentName: "",
    };
  },
  async mounted() {},
  methods: {
    ...mapActions({}),
  },
  computed: {},
};
</script>

<style scoped></style>
