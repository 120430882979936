<template>
  <div>
    <b-row class="d-flex align-items-start justify-content-start py-1">
      <b-col md="8" class="px-2 py-1">
        <h3 class="font-weight-bolder text-colorBlack">My Subscription</h3>
        <p class="font-weight-bold text-colorGrey">Your Subscription</p>
      </b-col>
      <b-col md="4" class="px-3 py-1 text-right">
        <b-button
          type="submit"
          variant="danger"
          rounded
          class="align-items-center"
          @click="cancelSubscription"
        >
          <feather-icon icon="XIcon" class="mr-50 text-colorWhite" />
          <span class="text-colorWhite">Cancel Subscription</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-between">
      <b-col
        md=""
        class="d-flex align-items-center justify-content-start bg-colorWhite w-100 py-2 px-2 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="text-left">
          <p class="text-colorBlack font-weight-bold" style="font-size: 0.9rem">
            Current Plan
          </p>
          <h1
            style="font-size: 1.6rem"
            class="text-colorBlack font-weight-bolder"
          >
            Premium
          </h1>
          <div>
            <p
              class="text-colorBlack font-weight-bold pt-50"
              style="font-size: 0.9rem"
            >
              Activated on 04/09/2020
            </p>
          </div>
        </div>
      </b-col>
      <b-col
        md=""
        class="d-flex align-items-center justify-content-start bg-colorWhite w-100 py-2 px-2 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="text-left">
          <p class="text-colorBlack font-weight-bold" style="font-size: 0.9rem">
            Validity
          </p>
          <h1
            style="font-size: 1.6rem"
            class="text-colorBlack font-weight-bolder"
          >
            365 Days
          </h1>
          <div>
            <p
              class="text-colorBlack font-weight-bold pt-50"
              style="font-size: 0.9rem"
            >
              Until 04/10/2020
            </p>
          </div>
        </div>
      </b-col>
      <b-col
        md=""
        class="d-flex align-items-center justify-content-start bg-colorWhite w-100 py-2 px-2 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="text-left">
          <p class="text-colorBlack font-weight-bold" style="font-size: 0.9rem">
            Plan Usage
          </p>
          <h1
            style="font-size: 1.6rem"
            class="text-colorBlack font-weight-bolder"
          >
            50%
          </h1>
          <div>
            <p
              class="text-colorBlack font-weight-bold pt-50"
              style="font-size: 0.9rem"
            >
              Usage Details
            </p>
          </div>
        </div>
      </b-col>
      <b-col
        md=""
        class="d-flex align-items-center justify-content-start bg-colorWhite w-100 py-2 px-2 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="text-left">
          <p class="text-colorBlack font-weight-bold" style="font-size: 0.9rem">
            Last Payment
          </p>
          <h1
            style="font-size: 1.6rem"
            class="text-colorBlack font-weight-bolder"
          >
            $24
          </h1>
          <div>
            <p
              class="text-colorBlack font-weight-bold pt-50"
              style="font-size: 0.9rem"
            >
              03/03/2024
            </p>
          </div>
        </div>
      </b-col>
      <b-col
        md=""
        class="d-flex align-items-center justify-content-start bg-colorWhite w-100 py-2 px-2 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="text-left">
          <p class="text-colorBlack font-weight-bold" style="font-size: 0.9rem">
            Next Payment
          </p>
          <h1
            style="font-size: 1.6rem"
            class="text-colorBlack font-weight-bolder"
          >
            In 30 days
          </h1>
          <div>
            <p
              class="text-colorBlack font-weight-bold pt-50"
              style="font-size: 0.9rem"
            >
              $24 on 04/10/2020
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="">
        <b-card>
          <div class="text-center mt-1">
            <h3 class="font-weight-bolder text-colorBlack">Freemium</h3>
            <p class="font-weight-normal text-colorGray">
              Fair Enough for Small Groups
            </p>
            <div class="pb-4 pt-2">
              <h1 class="font-weight-bolder text-colorBlue">$24.00</h1>
            </div>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="text-center pt-5 pb-1">
            <b-button size="md" rounded variant="colorBlue" class="px-5">
              Join Free</b-button
            >
          </div></b-card
        >
      </b-col>
      <b-col md="">
        <b-card>
          <div class="text-center mt-1">
            <h3 class="font-weight-bolder text-colorBlack">Premium</h3>
            <p class="font-weight-normal text-colorGray">
              Works for Medium Scale Institution
            </p>
            <div class="pb-1 pt-2">
              <h1 class="font-weight-bolder text-colorBlue">$24.00</h1>
              <p class="font-weight-normal text-colorBlack m-0 p-0">
                per month, pay monthly
              </p>
              <p class="font-weight-bolder text-colorBlack m-0 p-0">
                $200 per year, pay annually
              </p>
            </div>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="text-center pt-5 pb-1">
            <b-button size="md" rounded variant="colorBlue" class="px-5">
              Join Free</b-button
            >
          </div></b-card
        >
      </b-col>
      <b-col md="">
        <b-card>
          <div class="text-center mt-1">
            <h3 class="font-weight-bolder text-colorBlack">Professional</h3>
            <p class="font-weight-normal text-colorGray">
              Perfect for Large Institutioins
            </p>
            <div class="pb-1 pt-2">
              <h1 class="font-weight-bolder text-colorBlue">$49.00</h1>
              <p class="font-weight-normal text-colorBlack m-0 p-0">
                per month, pay monthly
              </p>
              <p class="font-weight-bolder text-colorBlack m-0 p-0">
                $400 per year, pay annualy
              </p>
            </div>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="text-center pt-5 pb-1">
            <b-button size="md" rounded variant="colorBlue" class="px-5">
              Join Free</b-button
            >
          </div></b-card
        >
      </b-col>
      <b-col md="">
        <b-card>
          <div class="text-center mt-1">
            <h3 class="font-weight-bolder text-colorBlack">custom</h3>
            <p class="font-weight-normal text-colorGray">
              Lets Discuss your Way!
            </p>
            <div class="pb-4 pt-2">
              <h1 class="font-weight-bolder text-colorBlue">-</h1>
              <p class="font-weight-normal text-colorBlack m-0 p-0"></p>

              <p class="font-weight-bolder text-colorBlack m-0 p-0"></p>
            </div>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="pl-2 py-50">
            <span class="font-weight-normal text-colorBlack">
              <feather-icon
                icon="CheckIcon"
                class="mr-50 text-danger"
                size="20"
              />
              1 Staff member
            </span>
          </div>
          <div class="text-center pt-5 pb-1">
            <b-button size="md" rounded variant="colorBlue" class="px-5">
              Join Free</b-button
            >
          </div>
        </b-card>
      </b-col>
    </b-row>
    <CancelSubscriptionModal
      @modalClosed="onModalClosed"
      :key="`cancel-${cancelSubscriptionModalCount}`"
    />
    <SuccessfullyCancelSubscriptionModal
      @modalClosed="onModalClosed"
      :key="`success-${SuccessfullyCancelSubscriptionModal}`"
    />
    <UnsuccessfullyCancelSubscriptionModal
      @modalClosed="onModalClosed"
      :key="`unSuccess-${UnsuccessfullyCancelSubscriptionModal}`"
    />
  </div>
</template>

<script>
import CancelSubscriptionModal from "@/components/uniHead/subscription/CancelSubscriptionModal.vue";
import SuccessfullyCancelSubscriptionModal from "@/components/uniHead/subscription/SuccessfullyCancelSubscriptionModal.vue";
import UnsuccessfullyCancelSubscriptionModal from "@/components/uniHead/subscription/UnsuccessfullyCancelSubscriptionModal.vue";
export default {
  components: {
    CancelSubscriptionModal,
    SuccessfullyCancelSubscriptionModal,
    UnsuccessfullyCancelSubscriptionModal,
  },
  data() {
    return {
      cancelSubscriptionModalCount: 0,
      successfullyCancelSubscriptionModalCount: 0,
      unSuccessfullyCancelSubscriptionModalCount: 0,
    };
  },
  methods: {
    cancelSubscription() {
      this.CancelSubscriptionModal += 1;
      this.$nextTick(() => {
        this.$bvModal.show("cancel-subscription-modal");
      });
    },

    async onModalClosed() {},
  },
};
</script>

<style>
.my-bg {
  background: url("~@/assets/images/icons/uniHead/dashboard/subscription.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
</style>
